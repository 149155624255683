import {getCartList,getCartSum} from "@/api/user";

const app = {
    state: {
        isLogin:false,
        cartNum:0,
        carPrice:0,
        cartList:[],
        cartSelected: []
    },
    mutations:{
        SET_CARTNUM:(state, cartNum) => {
            state.cartNum = cartNum
        },
        SET_CARTPRICE:(state, carPrice) => {
            state.carPrice = carPrice
        },
        SET_CARTLIST:(state, cartList) => {
            state.cartList = cartList
        },
        SET_CARTSELECTED:(state, cartSelected) => {
            state.cartSelected  = cartSelected
        },
        SET_ISLOGIN:(state, isLogin) => {
            state.isLogin = isLogin
        }
    },
    actions:{
        GetCartList({commit}){
            return new Promise((resolve, reject) => {
                getCartList({}).then(res=>{
                    let list = res.data.list
                    let nums = 0
                    let price = 0
                    list.forEach(function (val){
                        nums = nums + val.nums
                        price = price + val.products.price * val.nums
                    })

                    commit('SET_CARTLIST', list)
                    commit('SET_CARTNUM', nums)
                    commit('SET_CARTPRICE',parseFloat(price).toFixed(2))
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        GetCartSum({commit}){
            return new Promise((resolve, reject) => {
                getCartSum().then(res=>{
                    commit('SET_CARTNUM', res.data)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    }
}

export default app
