<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import store from '@/store'
import {getConfig} from "@/api/config";
export default {
  name: 'App',
  components: {
  },
  created() {
    // 获取站点信息
    // 每进页面都更新下，还是设置个过期时间？
    getConfig({}).then(res => {
      store.commit('settings/CHANGE_SETTING', { key: "webConfig", value: res.data })
    })
  }
}
</script>
