import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'


import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/style/base.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/style/quicklinks.css'
import '@/assets/style/style.css'
import '@/assets/style/preview.css'
import '@/assets/style/purebox.css'

Vue.use(ElementUI)

import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

import '@/permission'


import h from './lib/index'
Vue.use(h)

Vue.config.productionTip = false

window.app = new Vue({
  el: '#app',
  router,
  store,
  data: {
    eventHub: new Vue()
  },
  render: h => h(App)
})
