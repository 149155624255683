import defaultSettings from '@/settings'
import dev from '@/utils/dev'

const {
  config
} = defaultSettings

const state = {
  webConfig: dev.storageGet('webConfig') === null ? config : dev.storageGet('webConfig'),
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    state[key] = value
    dev.storageSet({
      [key]: value
    })
  },
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
