import request from '@/utils/request'

export function login(data){
    return request({
        url: '/oauth/Login',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        needToken: false,
        data
    })
}

export function getUserInfo(systemType){
    if(!systemType){
        systemType = 'Web';
    }

    return request({
        //url: `/Common/Login/CurrentUser/${systemType}`,
        url: `/oauth/CurrentUser?type=${systemType}`,
        method: 'get',
        needToken: true
    })
}

export function getCartList(data){
    return request({
        url: '/app/mall/cart/list',
        method: 'POST',
        needToken: true,
        data
    })
}

export function getCartDetail(params){
    return request({
        url: '/api/Order/OrderDetails',
        method: 'post',
        needToken: true,
        data: params
    })
}

export function getCartNumber(params){
    return request({
        url: '/api/User/GetCartNumber',
        method: 'post',
        needToken: true,
        data: params
    })
}

export function getCartSum(){
    return request({
        url: '/app/mall/cart/number',
        method: 'get',
        needToken: true
    })
}

export function setCartNum(params){
    return request({
        url: '/api/Cart/SetCartNum',
        method: 'post',
        needToken: true,
        data: params
    })
}

// export function deleteCart(params){
//     return request({
//         url: '/api/Cart/DoDelete',
//         method: 'post',
//         needToken: true,
//         data: params
//     })
// }

export function getUserDefaultShip(params){
    return request({
        url: '/api/User/GetUserDefaultShip',
        method: 'post',
        needToken: true,
        data: params
    })
}

export function getQCReportPage(params){
    return request({
        url: '/api/User/GetQCReportPage',
        method: 'post',
        needToken: true,
        data: params
    })
}

export function updatePassword(data) {
    return request({
        url: '/Permission/Users/Current/Actions/ModifyPassword',
        method: 'POST',
        needToken: true,
        data
    })
}
