module.exports = {
  data() {
    return {
      co: process.env.VUE_APP_CO,
      staticUrl: process.env.VUE_APP_STATIC_URL,
      tenantId: process.env.VUE_APP_TENANT_ID,
      systemId: process.env.VUE_APP_SYSTEM_ID
    }
  }
}
