import store from '@/store'

const title = store.state.settings.webConfig.platformName

export default function getPageTitle(key, fullName) {
  const realTitle = title
  // const hasKey = i18n.te(`route.${key}`)
  // if (hasKey) {
  //   const pageName = i18n.t(`route.${key}`)
  //   return `${pageName} - ${realTitle}`
  // }
  return fullName ? `${fullName} - ${realTitle}` : realTitle
}
