import axios from 'axios'
import router from '@/router'
import {Message} from 'element-ui'
import {debounce} from 'throttle-debounce'
import store from '@/store'
//
const errorMessage = debounce(500, (message, type = 'error') => {
    Message({
        message: message,
        duration: 10000,
        type: type
    })
})

const service = axios.create({
  // baseURL: "http://i.yabyy.com:5000/api",
  // baseURL: "https://mallapi.hc123.com.cn:7001/api",
  baseURL: "https://api.jshlyy.com.cn:83/api",
  // baseURL: "/api",
  // baseURL: "",
  timeout:5000000
});

// request拦截器
service.interceptors.request.use(
    config => {
        let userToken = localStorage.getItem("userToken")
        // if(userToken && config.needToken){
        //     // config.headers['Authorization'] = 'Bearer '+userToken
        //     config.headers['Authorization'] = userToken
        // }

        // 应该是有 token 就传，没有就不传
        if(userToken){
            // config.headers['Authorization'] = 'Bearer '+userToken
            config.headers['Authorization'] = userToken
        }

        config.headers['Tenantid'] = process.env["VUE_APP_TENANT_ID"]
        config.headers['Systemid'] = process.env["VUE_APP_SYSTEM_ID"]
      config.headers['dev-origin'] = 'h5'

        if (config.method == 'get') {
            config.params = config.data
        }
        let timestamp = Date.parse(new Date()) / 1000
        if (config.url.indexOf('?') > -1) {
            config.url += `&n=${timestamp}`
        } else {
            config.url += `?n=${timestamp}`
        }
        return config

    },
    error => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// response拦截器
service.interceptors.response.use(function (response) {
    const res = response.data

    if(res.code == 200){
        return res;
    }else{
        if(res.code == 600 || res.code == 601 || res.code == 602){
            errorMessage(res.msg)

            localStorage.removeItem('userToken')
            localStorage.removeItem('userInfo')
            store.commit('SET_CARTNUM', 0)
            store.commit('SET_CARTPRICE',0)
            store.commit('SET_ISLOGIN', false)

            router.replace({
                path:'/login'
            })
        }else{
            errorMessage(res.msg)
        }
    }
    // return res;
}, error => {
    if (error.response) {
        const res = error.response
        if (res.status == 500) {
            errorMessage('网络错误，请检查您的网络')
        } else if (res.data && res.data.msg) {
            errorMessage(res.data.msg)
        }
    }
    return Promise.reject(error)
})

export default service
