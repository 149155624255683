import Vue from 'vue'
import Router from 'vue-router'

// Router.beforeEach((to, from, next) => {
//     /* 路由发生变化修改页面title */
//     if (to.meta.title) {
//         document.title = to.meta.title == undefined ? this.siteName : to.meta.title + '_' + this.siteName
//     }
//     next()
// })

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const constantRouterMap = [{
    path: '/',
    meta:{
        requiresAuth: false,  //需要登录才能访问
        title: '订货会'
    },
    component: () => import('@/views/Index')
    // component: () => import('@/views/meeting/main.vue')
},{
    path: '/goods',
    name: 'Goods',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/Good')
},{
    path: '/category',
    name: 'Category',
    meta:{
        requiresAuth: true,  //需要登录才能访问
        title: '分类'
    },
    component: () => import('@/views/Category')
},{
    path: '/search',
    name: 'Search',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/Search')
},{
    path: '/promotion',
    name: 'Promotion',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/Promotion')
},{
    path: '/new',
    name: 'New',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/New')
},{
    path: '/special',
    name: 'Special',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/Special')
},{
    path: '/zhongqiu',
    name: 'ZhongQiu',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/zhongqiu')
},{
    path: '/ChuYou',
    name: '出游 十一活动',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/ChuYou')
},{
    path: '/dongji',
    name: '冬季热卖',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/DongJi')
},{
    path: '/sanjiu',
    meta:{
        title:'三九专区',
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/sanjiu')
},{
    path: '/nvshen',
    name: '女士节',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/nvshen')
},{
    path: '/sanbajie',
    name: '三八节',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/sanbajie')
},{
    path: '/huxi',
    name: '呼吸专场',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/huxi')
},{
    path: '/liushen',
    name: '六神专场',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/liushen')
},{
    path: '/jxy',
    name: '江小药',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/jxy')
},{
    path: '/mxld',
    name: '曼秀雷敦',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/special/mxld')
},{
    path: '/jianhuo',
    name: '国际尖货',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/jianhuo')
},{
    path: '/xiaolin',
    meta:{
        title:'小林专场',
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/xiaolin')
},{
    path: '/zb',
    name: '直播',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/zb')
},{
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
},{
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/register.vue')
},{
    path: '/member',
    name: 'member',
    redirect: '/orderList',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/member/Index'),
    children:[
      {
            path: '/orderList',
            name: 'OrderList',
            meta:{
                title:'我的订单 - 个人中心',
                requiresAuth: true  //需要登录才能访问
            },
            component: () => import('@/views/member/OrderList')
        },
        {
            path: '/accountSafe',
            name: 'AccountSafe',
            meta:{
                requiresAuth: true  //需要登录才能访问
            },
            component: () => import('@/views/member/AccountSafe')
        },
        {
            path: '/changePassword',
            name: 'ChangePassword',
            meta:{
                requiresAuth: true  //需要登录才能访问
            },
            component: () => import('@/views/member/ChangePassword')
        },
        {
            path: '/QCReport',
            name: 'QCReport',
            meta:{
                requiresAuth: false  //需要登录才能访问
            },
            component: () => import('@/views/member/QCReport')
        }
    ]
},{
    path: '/news',
    name: 'news',
    meta:{
        requiresAuth: true  //需要登录才能访问
    },
    component: () => import('@/views/news/index')
},{
    path: '/article',
    name: 'article',
    meta:{
        requiresAuth: false  //需要登录才能访问
    },
    component: () => import('@/views/news/article/index'),
    children:[
        {
            path: '/article/view',
            name: 'view',
            meta:{
                requiresAuth: false  //需要登录才能访问
            },
            component: () => import('@/views/news/article/view')
        },
        {
            path: '/article/page',
            name: 'page',
            meta:{
                requiresAuth: false  //需要登录才能访问
            },
            component: () => import('@/views/news/article/page')
        }
    ]
},{
    path: '/marketing/promotion',
    name: 'marketing',
    meta:{
        requiresAuth: false  //需要登录才能访问
    },
    component: () => import('@/views/marketing/promotion/index')
},
    // 购物车
    {
        path: '/cart',
        name: 'cart',
        meta:{
            requiresAuth: true  //需要登录才能访问
        },
        component: () => import('@/views/cart/index'),
    },
    {
        path: '/cart/settlement',
        name: 'settlement',
        meta:{
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/cart/settlement')
    },
    {
        path: '/cart/success',
        name: 'cartSuccess',
        meta:{
            requiresAuth: true  //需要登录才能访问
        },
        component: () => import('@/views/cart/success')
    },
    {
        path: '/buy',
        name: 'buy',
        meta:{
            requiresAuth: true  //需要登录才能访问
        },
        component: () => import('@/views/cart/buy')
    },
    {
        path: '/2024',
        name: '2024江药常州订货会 - 首页',
        meta: {
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/meeting/main')
    },
    {
        path: '/sup',
        name: '2024江药常州订货会 - 厂家',
        meta: {
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/meeting/sup')
    },
    {
        path: '/marketing/lottery/log',
        name: '2024江药常州订货会 - 抽奖',
        meta: {
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/marketing/luckdraw/log')
    },
    {
        path: '/marketing/lottery/nineGrid',
        name: '2024江药常州订货会 - 抽奖',
        meta: {
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/marketing/luckdraw/nineGrid')
    },
    {
        path: '/marketing/lottery/nineGrid/info',
        name: '2024江药常州订货会 - 抽奖规则',
        meta: {
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/marketing/luckdraw/nineGrid/info')
    },
    {
        path: '/marketing/SmashGoldenEggs',
        name: 'SmashGoldenEggs',
        meta:{
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/marketing/luckdraw/goldenEggs/index'),
    },{
        path: '/marketing/SmashGoldenEggs/rule',
        name: 'JiuGongGrid',
        meta:{
            requiresAuth: false  //需要登录才能访问
        },
        component: () => import('@/views/marketing/luckdraw/goldenEggs/info'),

    }
]

export default new Router({
    mode: 'hash',
    // mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: constantRouterMap
})
