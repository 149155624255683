import router from "./router";
import getPageTitle from "@/utils/get-page-title";


router.beforeEach((to, from, next) => {
    document.title = getPageTitle(to.meta.title, to.meta.zhTitle)

    if(to.meta.requiresAuth ){
        if(JSON.parse(localStorage.getItem("userInfo"))==null){
            next({
                path: '/login',
                query: {redirect: to.fullPath}   //登录成功后重定向到当前页面
            });
        } else {
            next()
        }
    }else{
        next()
    }
})
