const getters = {
    isLogin: state => state.app.isLogin,
    cartNum: state => state.app.cartNum,
    carPrice:state => state.app.carPrice,
    cartList: state => state.app.cartList,
    cartSelected: state => state.app.cartSelected,
    webConfig: state => state.settings.webConfig
}
/**
 * 使用说明
 * import { mapGetters } from 'vuex'
 * computed: {
    ...mapGetters([
      'userInfo'
    ])
  }
 */
export default getters
