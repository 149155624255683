import request from '@/utils/request'

export function getConfig(params){
  return request({
    url: '/app/mall/config',
    method: 'get',
    needToken: false,
    data: params
  })
}
